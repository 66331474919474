import { browser } from '$app/environment';

export const getFromLocalStorage = (key) => {
	if (browser) {
		const item = localStorage.getItem(key);
		if (item) {
			return item;
		}
	}
	return null;
};

export const storeInLocalStorage = (key, value) => {
	if (browser) {
		localStorage.setItem(key, value);
	}
};
